import { KSA_COUNTRY_CODE } from "@Constants/index";
import { SnapchatPurchase, SnapchatSignup, SnapchatViewcontent } from "types/socialPixels";

declare global {
  interface Window {
    snaptr: (command: string, eventName: string, params?: object) => void;
  }
}

export const snapPurchaseEvent = ({
  id,
  type,
  status,
  value,
  currency,
  user_data: { user_id, user_name, user_email },
}: SnapchatPurchase) => {
  window.snaptr("track", "PURCHASE", {
    id,
    type,
    status,
    value,
    currency,
    user_data: {
      user_id,
      user_name,
      user_email,
    },
  });
};

export const snapViewContentEvent = ({
  content_id,
  content_name,
  content_type,
  value,
  currency,
}: SnapchatViewcontent) => {
  window.snaptr("track", "VIEW_CONTENT", {
    content_id,
    content_name,
    content_type,
    value,
    currency,
  });
};

export const snapSignupEvent = ({ id, email, username, signup_method }: SnapchatSignup) => {
  window.snaptr("track", "SIGN_UP", {
    id,
    email,
    username,
    signup_method,
    source: "website",
  });
};

export const snapContentTracking = (countryCode, contentType, contentData) => {
  if (typeof window !== "undefined" && window.snaptr && countryCode === KSA_COUNTRY_CODE) {
    snapViewContentEvent({
      content_id: contentData?.id,
      content_name: contentData?.name,
      content_type: contentType,
      value: Number(contentData?.pricing?.price),
      currency: contentData?.pricing?.currency,
    });
  }
};

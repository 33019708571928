// @ts-nocheck
import { useRouter } from "next/router";
import { useEffect } from "react";

export const FacebookPixelProvider = ({ children }) => {
  const router = useRouter();
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_PROJECT_ENVIRONMENT === "production") {
      if (typeof window !== "undefined") {
        import("react-facebook-pixel")
          .then((module) => module.default)
          .then((ReactPixel) => {
            ReactPixel.init(`${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}`);
            ReactPixel.pageView();
            router.events.on("routeChangeComplete", () => {
              ReactPixel.pageView();
            });
          });
      }
    }
  }, []);

  return children;
};
